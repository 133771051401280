"use strict";

module.exports = {
  EN: {
    directory: "Directory",
    showDirectory: "Show directory",
    showSearch: "Show search",
    showInfo: "Show info",
    hideDirectory: "Hide directory",
    close: "Close ",
    zoomIn: "Zoom in",
    zoomOut: "Zoom out",
    search: "Search",
    whenSearch: "When you search, or click on map markers, you'll see the results here",
    loading: "Loading",
    showDatasets: "Show datasets",
    searchInitiatives: "Search initiatives",
    any: "Any",
    searchIn: "Search in ?",
    clearFilters: "Clear Filters",
    contact: "Contact",
    allEntries: "All Entries",
    allCountries: "All Countries",
    aboutTitle: "About",
    underConstruction: "This section is under construction.",
    source: "The source data of the content is here:",
    technicalInfo: "Technical information about the technology behind this map and directory can be found here:",
    contributers: "contributers",
    otherData: "Other data",
    datasets: "Datasets",
    mixedSources: "Mixed Sources",
    poweredBy: "Powered by Geoapify",
    mapDisclaimer: "This map contains indications of areas where there are disputes over territories. The ICA does not endorse or accept the boundaries depicted on the map.",
    property_shortPostcode: "Short postcode",
  },
  FR: {
    directory: "Annuaire",
    showDirectory: "Afficher l’annuaire",
    showSearch: "Afficher la recherche",
    showInfo: "Afficher les informations",
    hideDirectory: "Masquer l’annuaire",
    close: "Fermer ",
    zoomIn: "Zoom avant",
    zoomOut: "Zoom arrière",
    search: "Rechercher",
    whenSearch: "Lorsque vous effectuez une recherche ou cliquez sur les repères de la carte, les résultats s’affichent ici.",
    loading: "Chargement des données",
    showDatasets: "Afficher les ensembles de données",
    searchInitiatives: "Rechercher des initiatives",
    any: "Tout afficher",
    searchIn: "Rechercher dans ?",
    clearFilters: "Réinitialiser les filtres",
    contact: "Contact",
    allEntries: "Toutes les entrées",
    allCountries: "Tous les pays",
    aboutTitle: "À propos",
    underConstruction: "Cette section est en construction.",
    source: "Les données sources de ce contenu se trouvent ici:",
    technicalInfo: "Des informations techniques sur la technologie qui sous-tend cette carte et ce répertoire sont disponibles ici:",
    contributers: "Contributeurs",
    otherData: "Autres données",
    datasets: "Ensembles de données",
    mixedSources: "Sources mixtes",
    poweredBy: "Créé par Geoapify",
    mapDisclaimer: "Cette carte contient des indications sur les zones où il y a des différends au sujet territorial. L'ACI n'approuve ni n'accepte les frontières représentées sur la carte.",
    property_shortPostcode: "Code postal court",
  },
  ES: {
    directory: "Directorio",
    showDirectory: "Mostrar directorio",
    showSearch: "Mostrar búsqueda",
    showInfo: "Mostrar información",
    hideDirectory: "Ocultar directorio",
    close: "Cerrar ",
    zoomIn: "Acercar",
    zoomOut: "Alejar",
    search: "Buscar",
    whenSearch: "Los resultados de la búsqueda o selección en los marcadores del mapa se mostrarán aquí.",
    loading: "Cargando…",
    showDatasets: "Mostrar conjuntos de datos",
    searchInitiatives: "Buscar iniciativas",
    any: "Qualquiera",
    searchIn: "Buscar en…",
    clearFilters: "Borrar filtros",
    contact: "Contacto",
    allEntries: "Todas las entradas",
    allCountries: "Todos los países",
    aboutTitle: "Sobre este mapa",
    underConstruction: "Esta sección está en construcción.",
    source: "Los datos de origen de este contenido están aquí:",
    technicalInfo: "La información técnica sobre la tecnología que hay detrás de este mapa y directorio se puede encontrar aquí:",
    contributers: "Colaboradores",
    otherData: "Otros datos",
    datasets: "Conjuntos de datos",
    mixedSources: "Fuentes mixtas",
    poweredBy: "Desarrollado por Geoapify",
    mapDisclaimer: "Este mapa contiene indicaciones de zonas donde hay disputas territoriales. La ACI no respalda ni acepta las fronteras representadas en el mapa.",
    property_shortPostcode: "Código postal corto",
  },
  KO: {
    directory: "디렉토리",
    showDirectory: "디렉토리 표시",
    showSearch: "검색 표시",
    showInfo: "정보 표시",
    hideDirectory: "디렉토리 숨기기",
    close: "닫기",
    zoomIn: "쥼인",
    zoomOut: "쥼아웃",
    search: "검색",
    whenSearch: "검색시 혹은 지도표지를 클릭할시 결과는 여기서 볼수 있음.",
    loading: "로딩",
    showDatasets: "자료 표시",
    searchInitiatives: "이니셔티브 찾기",
    any: "아무거나",
    searchIn: "검색?",
    clearFilters: "필터 지우기",
    contact: "연락",
    allEntries: "모든 항목들",
    allCountries: "모든 나라들",
    aboutTitle: "정보",
    underConstruction: "이 섹션은 공사 중입니다.",
    source: "이 콘텐츠의 소스 데이터는 여기:",
    technicalInfo: "이 지도 및 디렉토리 이면의 기술에 대한 기술 정보를 찾을 수 있습니다 여기:",
    contributers: "기여자",
    otherData: "다른 자료",
    datasets: "데자료",
    mixedSources: "혼합",
    poweredBy: "Geoapify에 의해 구동됨",
    mapDisclaimer: "이 지도에는 영토에 대한 분쟁이 있는 지역의 표시가 포함되어 있습니다. ICA는 지도에 표시된 경계를 승인하거나 수락하지 않습니다.",
    property_shortPostcode: "짧은 우편번호",
  }
};
